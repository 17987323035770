// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jump-checkout-cancel-js": () => import("./../../../src/pages/jump/checkout-cancel.js" /* webpackChunkName: "component---src-pages-jump-checkout-cancel-js" */),
  "component---src-pages-jump-checkout-success-js": () => import("./../../../src/pages/jump/checkout-success.js" /* webpackChunkName: "component---src-pages-jump-checkout-success-js" */),
  "component---src-pages-jump-portal-return-js": () => import("./../../../src/pages/jump/portal-return.js" /* webpackChunkName: "component---src-pages-jump-portal-return-js" */),
  "component---src-pages-licensing-js": () => import("./../../../src/pages/licensing.js" /* webpackChunkName: "component---src-pages-licensing-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-solutions-analytics-js": () => import("./../../../src/pages/solutions/analytics.js" /* webpackChunkName: "component---src-pages-solutions-analytics-js" */),
  "component---src-pages-solutions-app-startups-js": () => import("./../../../src/pages/solutions/app-startups.js" /* webpackChunkName: "component---src-pages-solutions-app-startups-js" */),
  "component---src-pages-solutions-development-agencies-js": () => import("./../../../src/pages/solutions/development-agencies.js" /* webpackChunkName: "component---src-pages-solutions-development-agencies-js" */),
  "component---src-pages-solutions-digital-transformation-js": () => import("./../../../src/pages/solutions/digital-transformation.js" /* webpackChunkName: "component---src-pages-solutions-digital-transformation-js" */),
  "component---src-pages-solutions-enterprises-js": () => import("./../../../src/pages/solutions/enterprises.js" /* webpackChunkName: "component---src-pages-solutions-enterprises-js" */),
  "component---src-pages-solutions-fintech-js": () => import("./../../../src/pages/solutions/fintech.js" /* webpackChunkName: "component---src-pages-solutions-fintech-js" */),
  "component---src-pages-solutions-no-code-js": () => import("./../../../src/pages/solutions/no-code.js" /* webpackChunkName: "component---src-pages-solutions-no-code-js" */),
  "component---src-pages-terminal-js": () => import("./../../../src/pages/terminal.js" /* webpackChunkName: "component---src-pages-terminal-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

